

// Chakra imports
import { Box, SimpleGrid, Text, useStatStyles } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";

import {
  columnsDataDevelopment,
} from "views/admin/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";

import React, { useEffect, useState } from "react";
import useAxios from "Utils/useAxios";
import { useParams } from "react-router-dom";

export default function Settings() {
  const [columndata, setCoulumnData] = useState([])
  const [showData, setShowData] = useState(false)

  const { password, username } = useParams()

  useEffect(() => {
    if (!username || !password) {
      setShowData(false)
    }
    if (username === "ARBABITI" && password === "HARI@123") {
      setShowData(true)
    } else {
      setShowData(false)
    }
  }, [password, username])

  const axios = useAxios()

  const getAllCerts = async () => {
    try {
      const { data } = await axios.get('/certificate')
      console.log(data);
      setCoulumnData(data?.data)

    } catch (error) {
      console.log(error);
    }
  }

  console.log(tableDataDevelopment);

  useEffect(() => {
    getAllCerts()
  }, [])
  console.log(columnsDataDevelopment);

  const HeadersData = [
    { Header: 'ID', accessor: '_id' },
    { Header: 'SERIAL NUMBER', accessor: 'serialNumber' },
    { Header: 'NAME', accessor: 'name' },
    { Header: 'REGISTRATION NUMBER', accessor: 'registrationNumber' },
    { Header: 'TRADE', accessor: 'trade' },
  ]

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {
          showData ?
            <DevelopmentTable
              columnsData={HeadersData}
              tableData={columndata}
            /> : <Text color={'red'} fontSize={'xl'} textAlign={'center'} mt={'20%'}>User Not Logged In</Text>
        }

      </SimpleGrid>
    </Box>
  );
}




