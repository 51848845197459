

import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import * as React from 'react';
import AddModal from './AddModal';
import useAxios from 'Utils/useAxios';


export default function ComplexTable(props) {
  const axios= useAxios()
  const { tableData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [certdata, setCertdata] = React.useState([])

  React.useEffect(() => {
    setCertdata(tableData)
  }, [tableData])
  // Debugging statement to check the received data
  console.log("Received Table Data:", tableData);

  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const DeleteData = async (id) => {
    try {

      const {data}  =await axios.delete(`/certificate/${id}`)
      const filtered = certdata.filter((x)=>x._id!==id)
      setCertdata(filtered)
      
    } catch (error) {

    }
  }
  return (
    <Card
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: 'scroll', lg: 'hidden' }}
      overflowY={{ sm: 'scroll', lg: 'scroll' }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Certificate Data
        </Text>
        <AddModal isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setCertdata={setCertdata}

        />
      </Flex>
      <Box >
        <Table variant="simple" color="gray.500" mb="24px" mt="12px"   textTransform={'none'}>
          <Thead>
            <Tr>
              <Th borderColor={borderColor} color="gray.400">#</Th>
              <Th borderColor={borderColor} color="gray.400">NAME</Th>
              <Th borderColor={borderColor} color="gray.400">Serial Number</Th>
              <Th borderColor={borderColor} color="gray.400">Registration number</Th>
              <Th borderColor={borderColor} color="gray.400">trade</Th>
              <Th borderColor={borderColor} color="gray.400">Delete</Th>

            </Tr>

          </Thead>
          <Tbody style={{ border: "3px soid red", overflowX: "scroll" }}>
            {certdata && certdata.map((data, index) => <Tr>

              <Th textTransform={'none'}>{index + 1}</Th>
              <Th textTransform={'none'}>{data?.name}</Th>
              <Th textTransform={'none'}>{data?.serialNumber}</Th>
              <Th textTransform={'none'} >{data?.registrationNumber}</Th>
              <Th textTransform={'none'} >{data?.trade}</Th>
              <Th textTransform={'none'} ><Button colorScheme='red' size={'sm'}
              onClick={()=>DeleteData(data?._id)}
              >DELETE</Button></Th>
            </Tr>)}

          </Tbody>
        </Table>
      </Box>
    </Card>
  );
}
