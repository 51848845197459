import axios from 'axios';

const useAxios = () => {

    const axiosInstance = axios.create({
        baseURL: 'https://backend.arbabiti.in/api/v1', // Set your base URL
        timeout: 10000, // Optional: Set request timeout (10 seconds)
        headers: {
            'Content-Type': 'application/json', // Set default headers
            Authorization: `Bearer YOUR_TOKEN`, // Optional: Authorization token
        },
    });

    return axiosInstance
}

export default useAxios;