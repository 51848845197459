import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormLabel,
    Stack,
} from '@chakra-ui/react'
import useAxios from 'Utils/useAxios'

function AddModal({ isOpen, onOpen, onClose,setCertdata }) {
    const axios = useAxios()


    const initialData = {
        name: '',
        registrationNumber: '',
        serialNumber: '',
        trade: ''
    }
   
    const [inputdata, setInputData] = useState(initialData)

    const initialRef = React.useRef(null)
    const handleChange = (event) => {
        setInputData(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const onSubmit = async () => {
        const { name, registrationNumber, serialNumber, trade } = inputdata

        if (!name ||
            !registrationNumber ||
            !serialNumber ||
            !trade) {
            return

        }
        try {
            const { data } = await axios.post('/certificate', inputdata)
            console.log(data);
            setCertdata((pre)=>([...pre,data.data]))
            setInputData(initialData)
            onClose()

        } catch (error) {
            console.log(error);
        }
        console.log(inputdata);
    }

    return (
        <>
            <Button onClick={onOpen} colorScheme='blue'>+ Certificate</Button>


            <Modal
                initialFocusRef={initialRef}
                isOpen={isOpen}
                onClose={onClose}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Certificate</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Stack spacing={7}>
                            <FormControl>
                            <FormLabel>Name</FormLabel>
                                <Input ref={initialRef} name='name' placeholder='Name' onChange={handleChange} />
                            </FormControl>

                            <FormControl >
                            <FormLabel>Registration Number</FormLabel>
                                <Input name='registrationNumber' placeholder='Registration Number' onChange={handleChange} />
                            </FormControl>
                            <FormControl>
                            <FormLabel>Serial Number</FormLabel>
                                <Input name='serialNumber' placeholder='Serial Number' onChange={handleChange} />
                            </FormControl>

                            <FormControl>
                            <FormLabel>Trade</FormLabel>
                                <Input name='trade' placeholder='Trade' onChange={handleChange} />
                            </FormControl>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' mr={3}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>

                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>
    )
}

export default AddModal